import { EventTabName } from "@config/tabs";
import { LoginPageQueryParams } from "@uxf/cms/pages/login-page";
import { createRouter } from "@uxf/router";

export type RouteList = {
    // content-zone
    "content/content": { contentId: number; slug?: string };
    "content/contents": null;

    // faq-zone
    "question/question": { contentId: number; slug?: string };
    "question/questions": null;

    // main-zone
    "main-zone/change-password": null;
    "main-zone/create-password": { token: string };
    "main-zone/index": null;
    "main-zone/sentry-test": null;
    "main-zone/forgotten-password": null;
    "main-zone/login": LoginPageQueryParams;
    "main-zone/logout": null;
    "main-zone/guest-registration": null;
    "main-zone/create-club-event": { eventTemplateId: number };
    "main-zone/update-club-event": { id: number };
    "main-zone/update-club-results": { clubEventId: number };
    "main-zone/club-events": null;
    "main-zone/club-event": { id: number; tab?: EventTabName };
    "main-zone/csos-events": null;
    "main-zone/csos-event": { id: number; tab?: EventTabName };
    "main-zone/update-csos-event": { id: number };
    "main-zone/update-profile": { id: number };
    "main-zone/invite-profile": null;
    "main-zone/profile": { id: number };
    "main-zone/profiles": null;
    "main-zone/organized-events": null;
    "main-zone/create-organized-event": null;
    "main-zone/update-organized-event": { id: number };
    "main-zone/transactions": null;
    "main-zone/create-transactions": null;
    "main-zone/account": { profileId: number };
    "main-zone/club-settings": null;
    "main-zone/create-profile-subscription": null;
    "main-zone/update-profile-subscription": { id: number };
};

export default createRouter<RouteList>({
    // content-zone
    "content/content": "/clanky/[contentId]/[slug]",
    "content/contents": "/clanky",

    // faq-zone
    "question/question": "/caste-otazky/[contentId]/[slug]",
    "question/questions": "/caste-otazky",

    "main-zone/change-password": "/zmena-hesla",
    "main-zone/create-password": "/vytvoreni-hesla/[token]",
    "main-zone/forgotten-password": "/zapomenute-heslo",
    "main-zone/index": "/",
    "main-zone/sentry-test": "/sentry-test",
    "main-zone/login": "/prihlaseni",
    "main-zone/logout": "/odhlaseni",
    "main-zone/guest-registration": "/registrace",
    "main-zone/create-club-event": "/nova-oddilova-akce/[eventTemplateId]",
    "main-zone/update-club-event": "/nastaveni-oddilove-akce/[id]",
    "main-zone/update-club-results": "/nastaveni-vysledku/[clubEventId]",
    "main-zone/club-events": "/oddilove-akce",
    "main-zone/club-event": "/oddilova-akce/[id]",
    "main-zone/csos-events": "/zavody",
    "main-zone/csos-event": "/zavod/[id]",
    "main-zone/update-csos-event": "/nastaveni-zavodu/[id]",
    "main-zone/update-profile": "/nastaveni-uzivatele/[id]",
    "main-zone/invite-profile": "/novy-uzivatel",
    "main-zone/profile": "/uzivatel/[id]",
    "main-zone/profiles": "/uzivatele",
    "main-zone/organized-events": "/poradane-zavody",
    "main-zone/create-organized-event": "/novy-poradany-zavod",
    "main-zone/update-organized-event": "/nastaveni-poradaneho-zavodu/[id]",
    "main-zone/transactions": "/transakce",
    "main-zone/create-transactions": "/nove-transakce",
    "main-zone/account": "/konto/[profileId]",
    "main-zone/club-settings": "/nastaveni-klubu",
    "main-zone/create-profile-subscription": "/novy-typ-prispevku",
    "main-zone/update-profile-subscription": "/nastaveni-typu-prispevku/[id]",
});
